import React from "react"
import Seo from "../components/Seo"

const Test = () => {
  return (
    <>
      <Seo />
      <div style={{ textAlign: "center", marginTop: "10rem" }}>
        <h3>Sorry, this section is under development</h3>
      </div>
    </>
  )
}
export default Test
